var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { collapsed: _vm.collapsed },
      attrs: { id: "stepline-component" },
    },
    [
      _vm.stagelineLoaded
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "stepline-exit-stageline" } },
              [
                _c("stageline-back-button", {
                  staticClass: "mb-1",
                  attrs: { text: _vm.collapsed ? "Back" : "Back to Overview" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { id: "stepline-company-name" } },
              [
                !_vm.collapsed
                  ? _c("h4", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.company.name) + "\n      "
                      ),
                    ])
                  : _c("h4"),
                !_vm.collapsed
                  ? [
                      _c("div", { attrs: { id: "stepline-current-period" } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentPeriod?.title) +
                              "\n          "
                          ),
                        ]),
                        _c("br"),
                        _c(
                          "span",
                          {
                            attrs: { id: "stepline-current-jurisdiction-name" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.jurisdictionName) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { attrs: { id: "stepline-step-number" } }, [
              _vm._v("\n      STEPS\n    "),
            ]),
            _vm.steps
              ? _c(
                  "div",
                  { attrs: { id: "stepline-step-list" } },
                  _vm._l(_vm.steps, function (step) {
                    return _c(
                      "div",
                      {
                        key: step.id,
                        staticClass: "step",
                        class: {
                          previous: _vm.stepIsPrevious(step),
                          active: _vm.stepIsCurrent(step),
                          complete:
                            _vm.stepIsComplete(step) &&
                            !_vm.stepIsCurrent(step),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "indicator" },
                          [
                            _vm.stepIsComplete(step) && !_vm.stepIsCurrent(step)
                              ? _c("step-complete-icon")
                              : _vm._e(),
                            _vm.stepIsCurrent(step)
                              ? _c("step-current-icon")
                              : _vm._e(),
                          ],
                          1
                        ),
                        !_vm.collapsed
                          ? _c(
                              "div",
                              { staticClass: "mobile-font step-name" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(step.title || step.name) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [_c("ct-centered-spinner", { attrs: { variant: "primary" } })],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }