<template>
  <div id="stepline-component" :class="{ 'collapsed': collapsed }">
    <div v-if="stagelineLoaded">
      <div id="stepline-exit-stageline">
        <stageline-back-button
          class="mb-1"
          :text="collapsed ? 'Back' : 'Back to Overview'"
        />
      </div>
      <div id="stepline-company-name">
        <h4 v-if="!collapsed">
          {{ company.name }}
        </h4>
        <h4 v-else /> <!-- no text but keep native layout attrs -->
        <template v-if="!collapsed">
          <div id="stepline-current-period">
            <span>
              {{ currentPeriod?.title }}
            </span>
            <br>
            <span id="stepline-current-jurisdiction-name">
              {{ jurisdictionName }}
            </span>
          </div>
        </template>
      </div>
      <div id="stepline-step-number">
        STEPS
      </div>
      <div v-if="steps" id="stepline-step-list">
        <div
          v-for="step in steps"
          :key="step.id"
          class="step"
          :class="{
             'previous': stepIsPrevious(step),
             'active': stepIsCurrent(step),
             'complete': stepIsComplete(step) && !stepIsCurrent(step),
          }"
        >
          <div class="indicator">
            <step-complete-icon v-if="stepIsComplete(step) && !stepIsCurrent(step)" />
            <step-current-icon v-if="stepIsCurrent(step)" />
          </div>
          <div v-if="!collapsed" class="mobile-font step-name">
            {{ step.title || step.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="d-flex justify-content-center">
      <ct-centered-spinner variant="primary" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Stepline',
  components: {
    CtCenteredSpinner:   () => import('@/components/shared/CtCenteredSpinner'),
    StepCurrentIcon:     () => import('@images/ui/step-current.svg'),
    StepCompleteIcon:    () => import('@images/ui/step-complete.svg'),
    StagelineBackButton: () => import('@/components/StagelineV2/shared/StagelineBackButton'),
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('stageline', [
      'stagelineLoaded',
      'company',
      'jurisdiction',
      'currentStepIndex',
      'currentPeriod',
      'currentStage',
      'currentStep',
    ]),
    jurisdictionName() {
      return this.jurisdiction?.state_province_region
    },
    steps() {
      return this.currentStage?.steps.filter(steps => steps.slides?.length)
    },
  },
  methods: {
    stepIsPrevious(step) {
      return this.steps.findIndex(s => s.id === step.id) < this.currentStepIndex
    },
    stepIsCurrent(step) {
      return step.id === this.currentStep.id
    },
    stepIsComplete(step) {
      return this.steps.find(s => s.id === step.id).completed_at !== null
    },
  },
}
</script>

<style scoped lang="scss">
$violet2: #000864;

#stepline-component {
  padding: 4.6875em 1.25em 0 1.875em;
  color: $ct-ui-font-color-default-grey;

  #stepline-exit-stageline {
    position: absolute;
    top: 1.875em;
    left: 1.875em;
    font-size: smaller;
    font-weight: 900;
    a {
      color: $ct-ui-font-color-default-grey;
    }
  }
  #stepline-company-name {
    overflow-wrap: break-word;
    hyphens: auto;
    margin-bottom: 3.125em;
    h4 {
      margin: 0 0 0.625em 0;
      font-family: "Open Sans", "Roboto", sans-serif;
      font-size: 1.25em;
      font-weight: 600;
      color: $violet2;
    }
  }
  #stepline-current-jurisdiction,
  #stepline-current-period,
  #stepline-step-number {
    font-size: 0.75em !important;
    color: $ct-ui-font-color-secondary;
    font-weight: $ct-ui-font-weight-9;
    letter-spacing: 1.2px;
    #stepline-current-jurisdiction-name {
      font-size: 1.5em;
    }
  }
  #stepline-step-title {
    margin-bottom: 1.125em;
    font-size: 0.8125em;
    font-weight: 900;
  }
  #stepline-step-list {
    margin-left: 1.75em;
    font-size: 0.875em;
    position: absolute;
    .step {
      margin: 0 0 1.125em 0;
      position: relative;
      max-width: 13.125em;
      min-height: 1.25em;
      .indicator {
        position: absolute;
        left: -1.5em;
        top: 0.125em;
        background-color: transparent;
        border-radius: 50%;
        border: $ct-ui-stageline-graphic-linewidth solid $ct-ui-stepline-indicator-color;
        width: 1.125em;
        height: 1.125em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &.active {
        font-weight: 900;
        .indicator {
          background: $ct-ui-stepline-indicator-color-light;
          svg {
            overflow: visible;
            height: 1.375em !important;
            position: relative;
            left: 1px;
            path {
              fill: $ct-ui-stepline-indicator-color !important;
              stroke-width: 6 !important;
            }
          }
        }
      }
      &.complete {
        .indicator {
          background: $ct-ui-stepline-indicator-color;
          svg {
            overflow: visible;
            height: 1.625em !important;
            path {
              stroke-width: 6 !important;
            }
          }
        }
      }
    }
  }

  .active .indicator {
    background-color: $ct-ui-stepline-indicator-color-light;
    border: 1px solid $ct-ui-stepline-indicator-color;
    border-radius: 50%;
  }
  .complete .indicator {
    background-color: $ct-ui-stepline-indicator-color;
    border: 1px solid $ct-ui-stepline-indicator-color;
    border-radius: 50%;
  }
  &.collapsed {
    position: relative;
    right: 0.625em;
    #stepline-step-number {
      position: relative;
      right: 0.25em;
      width: max-content;
    }
    #stepline-step-list {
      margin-left: 2.125em;
      margin-top: 1.25em;
    }
  }
}

.v-padding-point-5 {
  padding: 0 0.5rem;
}

@media only screen and (max-width: 800px) {
  #stepline-component {
    padding: 1.0em;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;

    #stepline-exit-stageline {
      position: static;
      top: unset;
      left: unset;
      padding-bottom: 1.0em;
    }

    #stepline-company-name {
      margin-bottom: 1.0em;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      h4 {
        font-size: 1.125em;
      }

      #stepline-current-jurisdiction {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        column-gap: 0.2em;

        #stepline-current-jurisdiction-name {
          font-size: 1.2em;
        }
      }
    }

    #stepline-step-list {
      position: static;
      margin-left: 0;
      padding: 0 0 1.0em 2.0em;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      .step {
        width: 100%;
        position: static;
        margin-bottom: 0;
        padding-bottom: 0.5em;
        font-size: 0.875em;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .indicator {
          position: static;
          left: unset;
          top: unset;
          margin-right: 0.4em;
        }
      }
    }

    &.collapsed {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 1.125em;
  }
}
</style>
